import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { standardMargin } from "../theme/color";

import { useCookies } from "react-cookie";
import localIpUrl from "local-ip-url";

import {profilePath} from "../helper/constants";

import Question from "../components/Question";
import LocationPermissionRequest from "../components/LocationPermissionRequest/";

import HelloHeader from "../components/Header/HelloHeader";
import nextQuestionID from "../helper/nextQuestionID";
import EndofProfileCreation from "../components/PopUp/EndofProfileCreation";


export default () => {  
  const [page, setPage] = useState(0);
  const [answersArray, setAnswersArray] = useState<any[]>([]);
  const [inputType, setInputType] = useState(1);
  const [end, setEnd] = useState(false);
  const [cookies, setCookies] = useCookies([
    "profileBuilt",
    "latitude",
    "longitude",
    "timestamp",
    "ip",
    "name",
    "emailAddress",
    "count",
    "page",
    "boothAnswered",
    "language",
    "cookiesAllow"
  ]);

  useEffect(() => {
    if (cookies.name == null){
      setCookies("name", "Ms. ");
    }

    if (cookies.page && cookies.emailAddress) {
      setPage(cookies.page);
    }

    setInputType(profilePath[page].type);
  }, [setCookies, cookies, page]);

  const options = profilePath[page].options;

  const onSubmit = (input: any) => {
    const nextQuestionIndex = nextQuestionID(false, page, input);
    
    answersArray.push(input);
    setAnswersArray(answersArray);

    if (nextQuestionIndex === -1) {
      setEnd(true);
      setTimeout(() => setCookies("profileBuilt", true),3000);
      setTimeout(() => setPage(0),3000);
      setCookies("page", 0);

    }
    else{

      if (page === 1) {
        setCookies("emailAddress", input);
        setCookies("timestamp", Date.toLocaleString());
        setCookies("ip", localIpUrl());
      }
      
      setPage(nextQuestionIndex);
      setCookies("page", nextQuestionIndex);
      setCookies("cookiesAllow", true)
    }
  };

  const onCallBrowserLocationSetting = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCookies("latitude", position.coords.latitude);
        console.log(position.coords.latitude);
        setCookies("longitude", position.coords.longitude);
      });
    }
  };
  const onReject = () =>{
    setCookies("latitude", "unavailable");
  }

  return (
    <RegistrationContainer>
      {cookies.latitude == null && (
        <LocationPermissionRequest
          onCallBrowserLocationSetting={() => onCallBrowserLocationSetting()}
          onReject={() => onReject()}
        />
      )}
      {end && <EndofProfileCreation/>}
      <HelloHeader name={cookies.name}/>
      <QuestionContainer>
        <ProfileLabel>{profilePath[page].content[cookies.language]}</ProfileLabel>
          <Question
            page={page}
            onSubmit={onSubmit}
            inputType={inputType}
            options={options}
          />
      </QuestionContainer>
    </RegistrationContainer>
  );
};

const RegistrationContainer = styled.div`
  color: black;
  align-items: center;
  height: 50%;
`;

const QuestionContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 10%;
  overflow:hidden;
  margin-bottom: ${standardMargin*2}px;
`;

const ProfileLabel = styled.div`
  margin-left: 5px;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: ${standardMargin*2}px;
`;

