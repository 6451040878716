import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useCookies } from "react-cookie";
import otherInformation from "../../otherInformation.json"

export default ({
   name,
   boothNumber,
   boothName,
   language
 }: {
   name?: string;
   boothNumber?: string;
   boothName?: string;
   language: string;
 }) => {
   const [cookies, setCookies] = useCookies([
      "boothAnswered"
   ]);
   const [boothAnswered, setBoothAnswered] = useState<any[]>([]);

   useEffect(() => {
      if (cookies.boothAnswered == null){
         setCookies("boothAnswered", "");
      }
      else{
         setBoothAnswered(cookies.boothAnswered.split("|"));}
    }, [cookies, setCookies])

   return(
   <TopWrapper>
      <CountWrapper>
         <CountLabel>{otherInformation.boothCount[language]}{boothAnswered.length-1}</CountLabel>
         <NameLabel>{name}</NameLabel>
      </CountWrapper>
      <BoothInfo>
         <BoothNumberWrapper>
            <BoothNumberTxt>{boothNumber}</BoothNumberTxt>
         </BoothNumberWrapper>
         <BoothNameWrapper>
            <BoothNameTxt>{boothName}</BoothNameTxt>
         </BoothNameWrapper>
      </BoothInfo>
   </TopWrapper>
    );
};

const TopWrapper = styled.div`
   font-size: 11px;
   height: 60px;
   background-color: white;
   border-style: solid;
   border-width: 0px;
   border-bottom-width:1.5px;
   border-color: black;
`;

const CountLabel = styled.text`
   font-weight:bolder;
   color:black;
   float:left;
   margin-left: 5px;
   margin-top: 5px;
`;
const NameLabel = styled.text`
   font-weight:bolder;
   margin-right:5px;
   margin-left: auto;
   margin-top:5px;
   color:black;
`;

const CountWrapper = styled.div`
   height:20px;
   display: flex;
`;

const BoothInfo = styled.div`
   height: 40px;
   text-align:center;
   margin-left:5px;
`;

const BoothNumberTxt = styled.text`
color:black;
font-weight:bolder;
`;


const BoothNameTxt = styled.text`
color:black;
font-weight:bolder;

`;

const BoothNumberWrapper = styled.div`
height:10px;

`;

const BoothNameWrapper = styled.div`
height: 10px;
margin-top:5px;
`;