import React from 'react';
import styled from 'styled-components';
import {standardMargin} from "../../theme/color";
import LanguageChoice from "./LanguageChoice"
import { useCookies } from "react-cookie";


export default ({onAction}:{onAction (e:any):void;}) =>{

  const [cookies] = useCookies([
    "language"
  ]);

  const selectedLanguageEn = () => {
    onAction("en");
    onAction("close");
  }

  const selectedLanguageZh = () => {
    onAction("zh");
    onAction("close");
  }
  const onClose = () => {
    onAction("close");
  }
  

   return(
      <LanguageSwitchWrapper>
        <SwitchLabel>Select your preferred language</SwitchLabel>
        <LanguageChoice answer={"English"} onClick={selectedLanguageEn} isSelected={cookies.language === "en"}/>
        <LanguageChoice answer={"香港中文版"} onClick={selectedLanguageZh} isSelected={cookies.language === "zh"}/>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </LanguageSwitchWrapper>
   );

};

const SwitchLabel = styled.div`
  font-size: 16px;
  padding-bottom: ${standardMargin}px;
`;


const CancelButton = styled.div`
  color:black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 30px;
  font-size: 14px;
  margin-top: ${standardMargin*1.5}px;
  margin-bottom: ${standardMargin/2}px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
`;


const LanguageSwitchWrapper = styled.div`
  padding-top: ${standardMargin*1.5}px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-weight:bold;
  text-align: center;
  height: 220px;
  bottom: 0%;
  position: fixed;
  width: 100%;
  box-shadow: 0px -3px 20px -5px rgba(0, 0, 0, 0.75);
  align-items: center;
`;
