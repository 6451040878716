import React, { useState } from "react";
import styled from "styled-components";
import Choice from "./InputComponent/Choice";
import SubmitButton from '../SubmitButton';
import { standardMargin } from "../../theme/color";
import {useCookies} from 'react-cookie';


export default ({
  options,
  onSubmit
}: {
  options?:
    | { zh: string; en: string; nextID: number }[]
    | { zh: string; en: string }[];
  onSubmit (e:any): void;
}) => {
  const [selected, setSelected] = useState<boolean[]>([]);
  const [answer, setAnswer] = useState(0);
  const [cookies] = useCookies(['language']);

  var i;
  const tempSelected: boolean[] = [];
  const questionBank: string[] = [];

  if (options) {
    for (i = 0; i < options.length; i++) {
      questionBank.push(options[i][cookies.language]);
    }
  }

  const onSelect = (j: number) => {
    if (options) {
      for (i = 0; i < options.length; i++) {
        if (i === j) {
          tempSelected.push(true);
        } else {
          tempSelected.push(false);
        }
      }
      setSelected(tempSelected);
      setAnswer(j);
    }  
  };

  const submit = () => {
    if (options && options[answer].hasOwnProperty("optionID")) {
      onSubmit((options[answer] as any).optionID);
    }
  }

  return (
    <ButtonInputWrapper>
      <SingleSelect>
        {questionBank.map((value, index) => {
          return (
            <Choice
              letter={index}
              answer={value}
              onSelect={() => onSelect(index)}
              isSelected={selected[index]}
            />
          );
        })}
      </SingleSelect>
      <SubmitButton onSubmit={submit} />
    </ButtonInputWrapper>
  );
};

const ButtonInputWrapper = styled.div`
  height: auto;
  border-radius: ${35 / 3}px;
  width: 100%;
  border-width: 1.5px;
  border-color: black;
`;

const SingleSelect = styled.div`
  margin-top:${standardMargin}px;
  height: auto;
  position: relative;
  max-height: 80%;
  padding-left: auto;
  padding-right: auto;
  overflow-y:scroll;
`;
