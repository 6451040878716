import React from 'react';
import styled from 'styled-components';
import {standardMargin} from '../../../theme/color';

export default ({
  answer,
  onSelect,
  isSelected
}: {
  answer?:  string;
  onSelect?(e:any): void;
  isSelected?: boolean;
}) => {
  return (
    <ChoiceWrapper onClick= {onSelect} isSelected={isSelected}>
      <AnswerLabel>{answer}</AnswerLabel>
    </ChoiceWrapper>
  );
};

interface StyledButtonProps {
  isSelected?: boolean;
}

const AnswerLabel = styled.div`
  text-align:center;
  padding:5px;
  color:white;
  font-size: 12px;
`;

const ChoiceWrapper = styled.button`
  height: 30px;
  margin-right: ${standardMargin}px;
  border-radius: ${35 / 8}px;
  padding: ${standardMargin/8}px;
  background-color: ${({ isSelected }: StyledButtonProps) =>
    isSelected ? '#2E7A3B' : '#C4C4C4'};
  width: 45px;
  position: relative;
  margin-bottom: 5px;
  border-width:0px;
  align-items:center;
`;
