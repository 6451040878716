import React from 'react';
import styled from 'styled-components';
import {standardMargin} from "../../theme/color";

export default ({
  answer,
  onClick,
  isSelected
}: {
  answer?:  string;
  onClick?(): void;
  isSelected?: boolean;
}) => {
  return (
    <ChoiceWrapper onClick= {onClick} isSelected={isSelected}>
      {answer}
    </ChoiceWrapper>
  );
};

interface StyledButtonProps {
  isSelected?: boolean;
}

const ChoiceWrapper = styled.button`
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 35px;
  background-color: ${({ isSelected }: StyledButtonProps) =>
    isSelected ? '#2E7A3B' : '#C4C4C4'}; 
  border-width:0px; 
  margin-top: ${standardMargin*0.75}px;
  margin-bottom: ${standardMargin/2}px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
`;
