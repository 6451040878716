import React, { useState } from "react";
import styled from "styled-components";
import otherInformation from "../../otherInformation.json";
import {useCookies} from 'react-cookie';

import { standardMargin } from "../../theme/color";

export default ({
  onAccept
}: {onAccept(): void;}) => {
  const [hide, setHidden] = useState(false);
  const [cookies] = useCookies(['language']);

  const AllowButton = () => {
    setHidden(true);
    onAccept();
  };

  return (
    <CookieContainer isHidden={hide}>
      <CookieContent>
        {otherInformation.cookiesAlert[cookies.language]}
      </CookieContent>
      <ButtonWrapper>
        <OkButton onClick={AllowButton}>Ok</OkButton>
      </ButtonWrapper>
    </CookieContainer>
  );
};

const OkButton = styled.button`
  display:flex;
  font-weight:bold;
  height: auto;
  min-width: 50px;
  padding-top:5px;
  padding-bottom:5px;
  padding-right:10px;
  padding-left:10px;
  border-radius: ${35 / 3}px;
  border-width: 1.5px;
  border-color: black;
  background-color:white;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items:center;
  margin-right: ${standardMargin/2}px;
  justify-content:flex-end;
  max-width: 50px;

`;

const CookieContent = styled.div`
  font-size:11px;
  display: flex;
  max-width: 95%;
  justify-content: center;
  align-items: center;
  margin: ${standardMargin/2}px;
  margin-left: ${standardMargin}px;
`;

interface TimeOut {
  isHidden?: boolean;
}

const CookieContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0%;
  width: 100%;
  max-height: ${22*10}px;
  visibility: ${({ isHidden }: TimeOut) =>
    isHidden ? "hidden" : "visible"};
  box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.75);

`;
