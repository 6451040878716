import React, { useState} from "react";
import styled from "styled-components";
import SubmitButton from "../SubmitButton";
import ReactStars from 'react-stars';


export default ({
  onSubmit,
}: {
  onSubmit (e: any): void;
}) => {
  const [answer, setAnswer] = useState(0);

  const onChange = (starsNumber: number) => {
     setAnswer(starsNumber);
  };

  const submit = () => {
    onSubmit(answer);
  }

  return (
    <StarsWrapper>
      <ReactStars
        count={5}
        size={50}
        half={false}
        onChange={onChange}
        color2={'#ffd700'} />
      <SubmitButton onSubmit={submit} />
    </StarsWrapper>
  );
};

const StarsWrapper = styled.div`
  height: auto;
  border-radius: ${35 / 3}px;
  width: 100%;
  border-width: 1.5px;
  border-color: black;
`;
