import React, {useEffect} from "react";
import styled from "styled-components";
import UseOfCookieAlert from "./components/PopUp/UseOfCookieAlert";
import Footer from "./components/Footer";

import BoothQuestions from "./pages/BoothQuestions"
import ProfileCreation from "./pages/ProfileCreation"
import { useCookies } from "react-cookie";

export default () => {

  const [cookies, setCookies] = useCookies([
    "profileBuilt",
    "cookiesAllow",
    "language"
  ]);

  useEffect(() => {
    if (cookies.language == null){
      setCookies("language","en");
    }
  }, [setCookies, cookies]);

  const onAccept = () =>{
    setCookies("cookiesAllow",true);
  }

  const changeLanguage = (languageSelected: string) =>{
    setCookies("language",languageSelected);

  }

  return (
    <Container>
      {cookies.profileBuilt? <BoothQuestions/>:<ProfileCreation/>}
      {cookies.cookiesAllow? <Footer onChangeLanguage={changeLanguage}/>:<UseOfCookieAlert onAccept={() => onAccept()}/>}
    </Container>
  );
};

const Container = styled.div`
`;

// const animation_styles = keyframes`
//  0% { opacity: 1;}
//  50% { opacity: 1;}
//  100% { opacity: 0; }
// `;

// const CookieBar = styled.div`
//   position: fixed;
//   /* margin: 10px; */
//   bottom: 0%;
//   padding: ${standardMargin / 2}px;
//   color: black;
//   width: 90%;
//   animation-name: ${animation_styles};
//   animation-duration: 5s;
//   animation-fill-mode: forwards;
//   overflow: hidden;
//   height: ${standardMargin};
// `;
