import React from "react";
import styled from "styled-components";
import { standardMargin } from "../../theme/color";
import otherInformation from "../../otherInformation.json";
import {useCookies} from "react-cookie"

export default ({boothId}:{boothId: string}) => {
  const [cookies]=useCookies(["language"]);

  return (
    <EndofProfileCreationContainer>
      <EndofProfileCreationContent>
      <p>{otherInformation.endofBoothQuestion[cookies.language]}</p>
      <p>{boothId}</p>
      </EndofProfileCreationContent>
    </EndofProfileCreationContainer>
  );
};

const EndofProfileCreationContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  min-height: 200px;
  min-width: 300px;
  max-width: 80%;
  justify-content: center;
  align-items: center;
  padding: ${standardMargin}px;
  background-color: white;
  box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.75);
`;

const EndofProfileCreationContainer = styled.div`
  top: 0%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  background-color: transparent;
`;