import React, { ReactChild } from 'react';
import styled from 'styled-components';

interface ButtonProps {
  isOutline?: boolean;
  isWordOnly?: boolean;
  children?: ReactChild | ReactChild[];
  onClick?(): void;
}

export default ({
  isOutline,
  onClick,
  children
}: ButtonProps) => {
  return (
    <StyledButton
      onClick={onClick}
      isOutline={isOutline}
    >
      <StyledButtonText isOutline={isOutline}>{children}</StyledButtonText>
    </StyledButton>
  );
};

interface StyledButtonProps {
  isOutline?: boolean;
}

const StyledButton = styled.button`
  display:flex;
  height: auto;
  min-width: 104px;
  padding-top:5px;
  padding-bottom:5px;
  padding-right:10px;
  padding-left:10px;
  border-radius: ${35 / 3}px;
  border-width: 1.5px;
  background: ${({ isOutline }: StyledButtonProps) =>
    isOutline ? 'red' : 'white'};
  border-color: ${({ isOutline }: StyledButtonProps) =>
    isOutline ? 'white' : 'black'};
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledButtonText = styled.text`
  color: ${({ isOutline }: StyledButtonProps) =>
  isOutline ? 'white' : 'black'};
  font-size: 13px;
  font-weight: bold;
  text-align:center;
`;
