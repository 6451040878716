import React, { useState } from "react";
import styled from "styled-components";
import GenderChoice from "./InputComponent/GenderChoice";
import {standardMargin} from '../../theme/color';
import otherInformation from '../../otherInformation.json';
import {useCookies} from 'react-cookie';

export default ({onSelect}:{onSelect (e:string):void;}) => {
  const [cookies] = useCookies([
    "language"
  ]);

  const [selected, setSelected] = useState<boolean[]>([true, false, false]);

  const onChange = (answer: number) => {
    if (answer === 0){
      setSelected([true, false, false]);
    }
    else if (answer === 1){
      setSelected([false, true, false]);
    }
    else {
      setSelected([false, false, true]);
    }
    console.log(answer);
    switch (answer){
      case 0: onSelect("Ms. "); break;
      case 1: onSelect("Mr. "); break;
      case 2: onSelect("Mrs. "); break;
    };
  };

  return (
    <GenderWrapper>
      <GenderChoice
        answer={otherInformation.gender[0][cookies.language]}
        onSelect={() => onChange(0)}
        isSelected={selected[0]}
      />
      <GenderChoice
        answer={otherInformation.gender[1][cookies.language]}
        onSelect={() => onChange(1)}
        isSelected={selected[1]}
      />
      <GenderChoice
        answer={otherInformation.gender[2][cookies.language]}
        onSelect={() => onChange(2)}
        isSelected={selected[2]}
      />
    </GenderWrapper>
  );
};

const GenderWrapper = styled.div`
  display: flex;
  padding-right:${standardMargin}px;
  height: 45px;
  width: 100%;
`;
