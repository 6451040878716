import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { standardMargin } from "../theme/color";

import { useCookies } from "react-cookie";
import Question from "../components/Question";
import InformationHeader from "../components/Header/InformationHeader";

import {boothPath} from "../helper/constants"
import nextQuestionID from "../helper/nextQuestionID";

import EndofBoothQuestion from "../components/PopUp/EndofBoothQuestion";

const boothId = "5F-B25";
const boothName = "EventBinder Limited";

export default () => {
  const [end, setEnd] = useState(false);
  const [page, setPage] = useState(0);
  const [answersArray, setAnswersArray] = useState<any[]>([]);
  const [inputType, setInputType] = useState(1);
  const [boothAnswered, setBoothAnswered] = useState<any[]>([]);
  const [cookies, setCookies] = useCookies([
    "name",
    "emailAddress",
    "count",
    "page",
    "boothAnswered",
    "language"
  ]);
  
  useEffect(() => {
    if (!cookies.count){
      setCookies("count", 0);
    }
    else{
      setBoothAnswered(cookies.boothAnswered.split("|"));
    }
    setInputType(boothPath[page].type);
  }, [page, cookies, setCookies])

  var options = boothPath[page].options;

  const onSubmit = (input: any) => {
    const questionIndex = nextQuestionID(true, page, input);
    
    if (page === 0){
      if (boothAnswered.indexOf(boothId) === -1){
        boothAnswered.push(boothId);
        setCookies("count", (cookies.count)+1);
        setCookies("boothAnswered", boothAnswered.join("|"));
      }
    }
  
    if (questionIndex === -1) {
      answersArray.push(input);
      setAnswersArray(answersArray);
      
      var answerJson = {
        "email": cookies.email,
        "boothId": boothId,
        "answer": answersArray,
      };

      console.log(answerJson);
      
      setEnd(true);

    }
  
    else {
      answersArray.push(input);
      setAnswersArray(answersArray);

      setPage(questionIndex);
      setCookies("page", questionIndex);
    }
    console.log(answersArray);
    setAnswersArray(answersArray);
  };

  return (
    <RegistrationContainer>
      <InformationHeader
        name={cookies.name}
        boothNumber={boothId}
        boothName= {boothName}
        language={cookies.language}
      />
      {end && <EndofBoothQuestion boothId={boothId}/>}
      <QuestionContainer>
        <BoothLabel>{boothPath[page].content[cookies.language]}</BoothLabel>
        <QuestionWrapper>
          <Question
            page={page}
            onSubmit={onSubmit}
            inputType={inputType}
            options={options}
          />
        </QuestionWrapper>
      </QuestionContainer>
    </RegistrationContainer>
  );
};

const RegistrationContainer = styled.div`
  color: black;
`;

const QuestionContainer = styled.div`
  margin-top: ${standardMargin * 4}px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
`;

const BoothLabel = styled.text`
  color: #000;
  font-size: 15px;
  font-weight: bolder;
  margin-bottom: ${standardMargin * 2}px;
`;

const QuestionWrapper = styled.div`
  margin-top:${standardMargin*2}px;
  height: auto;
  position: relative;
  width:100%;
  max-height: 300px;
  padding-left: auto;
  padding-right: auto;
  overflow-y:scroll;
`;