import React, { useState } from 'react';
import styled from 'styled-components';
import Choice from './InputComponent/Choice'
import SubmitButton from '../SubmitButton';
import { standardMargin } from '../../theme/color';
import {useCookies} from 'react-cookie';

export default ({
  options,
  onSubmit
  
}: {
  options?: { zh: string; en: string; nextID: number; }[] | { zh: string; en: string; }[];
  onSubmit (e:any): void;

}) => {
  const [selected, setSelected] = useState<boolean[]>([]);
  const [arrayValue, setArrayValue] = useState<number[]>([]);
  const [cookies] = useCookies(['language']);
  
  var i;
  const tempSelected : boolean[] = [];
  const questionBank : string[] = [];

  if (typeof options != "undefined"){
    for (i=0; i<options.length; i++){
      questionBank.push(options[i][cookies.language]);
    }
  }
  
  const onSelect = (j: number) => {
    if (options){
      for (i=0; i<options.length; i++){
        if (i === j){
          tempSelected.push(!selected[i]);
        }
        else{
          tempSelected.push(selected[i]);
        }
      }
      setSelected(tempSelected);
    }
  }

  const submit = () => {
    var j;
    if (options && options[0].hasOwnProperty("optionID")) {
      console.log(selected);
      for (j = 0; j < selected.length; j++) {
        if (selected[j] === true) {
          console.log((options[j] as any).optionID);
          arrayValue.push((options[j] as any).optionID);
          setArrayValue(arrayValue);
        }
      }
    }
    onSubmit(arrayValue);
  }
  
  return (
    <ButtonInputWrapper>
      <MultiSelect>
        {
          questionBank.map((value, index)=>{
            return <Choice 
              letter={index} 
              answer={value}
              onSelect={() => onSelect(index)}
              isSelected={selected[index]}
            />
          })
        }
      </MultiSelect>
      <SubmitButton onSubmit={submit} />
    </ButtonInputWrapper>
  );
};
const ButtonInputWrapper = styled.div`
  height: auto;
  border-radius: ${35 / 3}px;
  width: 100%;
  border-width: 1.5px;
  border-color: black;
`;

const MultiSelect = styled.div`
  margin-top:${standardMargin}px;
  height: auto;
  position: relative;
  max-height: 350px;
  padding-left: auto;
  padding-right: auto;
  overflow-y:scroll;
`;
