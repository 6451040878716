import React from "react";
import styled from "styled-components";
import { Img } from "react-image";
import { standardMargin } from "../../theme/color";
import location_icon from "./img/undraw_my_location@3x.png";

interface PermissionRequestProps {
  a?: any;
  allow: boolean;
}

export default ({
  onCallBrowserLocationSetting,
  onReject
}: {
  onCallBrowserLocationSetting(): void;
  onReject(): void;
  
}) => {
  console.log("rendering Location Permission Request Container");

  return (
    <LocationPermissionRequestContainer>
      <LocationPermissionRequestContent>
        <p>Your location permission is disabled :(</p>
        <LocationIconContainer src={location_icon} />
        <h2>Allow your location</h2>
        <p>We will need your location to give you better experience</p>

        <AllowRejectButton onClick={onReject}> Not now </AllowRejectButton>
        <AllowRejectButton onClick={onCallBrowserLocationSetting}>
          Sure, I'd Like that
        </AllowRejectButton>
      </LocationPermissionRequestContent>
    </LocationPermissionRequestContainer>
  );
};

const LocationPermissionRequestContent = styled.div`
  text-align:center;
  display: flex;
  flex-direction: column;
  z-index: 100;
  min-height: 352px;
  min-width: 300px;
  max-width: 80%;
  justify-content: center;
  align-items: center;
  padding: ${standardMargin}px;
  background-color: white;
  box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.75);
`;

const LocationPermissionRequestContainer = styled.div`
  top: 0%;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
`;

const LocationIconContainer = styled(Img)`
  display: flex;
  height: 100px;
  width: 100px;
  /* background-color: red; */
`;

const AllowRejectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 30px;
  width: 200px;
  background-color: #C4C4C4;
  margin-top: 5px;
  margin-bottom: 5px;
`;
