import React from 'react'
import styled from 'styled-components'
import {standardMargin} from "../../theme/color"
import otherInformation from "../../otherInformation.json"
import {useCookies} from 'react-cookie';

export default ({
   name,
 }: {
   name?: string;
 }) =>{
  const [cookies] = useCookies([
    "language"
  ]);

   return(
      <HelloHeaderWrapper>
      <Title>
        <TitleLabel>{otherInformation.profileCreationTitle[cookies.language]}</TitleLabel>
      </Title>
      <NameWrapper>
         <NameLabel>{name}</NameLabel>
      </NameWrapper>
      </HelloHeaderWrapper>
   );

};

const HelloHeaderWrapper = styled.div`
  margin-top: ${standardMargin*2}px;
`;

const Title = styled.div`
  text-align: center;
`;
const TitleLabel = styled.text`
  color: black;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
`;

const NameWrapper = styled.div`
  text-align: center;
  height: ${standardMargin*4}px;
`;

const NameLabel = styled.text`
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;
