import React from 'react';
import TextInput from './InputType/TextInput';
import Stars from './InputType/Stars';
import SingleSelect from "./InputType/SingleSelect"
import MultiSelect from "./InputType/MultiSelect"
import Boolean from "./InputType/Boolean"
import { useCookies } from "react-cookie";



enum type {
  ShortTextInput = 1,
  LongTextInput = 2,
  SingleSelect = 3,
  MultiSelect = 4,
  MultiSelectWithOrder = 5,
  Boolean = 6,
  Rating = 7,
  MultiRating = 8,
}

export default ({
  page,
  inputType,
  options,
  onSubmit
}: {
  page?: number;
  onSubmit (e: any): void;
  inputType?: number;
  options?: { zh: string; en: string; nextID: number; }[] | { zh: string; en: string; }[] | undefined;
}) => {
  const [cookies] = useCookies(["profileBuilt"]);

  if (inputType === type.ShortTextInput) {
    if (page === 0 && cookies.profileBuilt == null){
      return(
        <TextInput
          page={page}
          onSubmit={(input) => onSubmit(input)}
          type="name"
        />
      );
    }
    if (page === 1 && cookies.profileBuilt == null){
      return(
        <TextInput
          page={page}
          onSubmit={(input) => onSubmit(input)}
          type="email"
        />
      );
    }
    else{
      return(
        <TextInput
          page={page}
          onSubmit={(input) => onSubmit(input)}
        />
      );
    }
  }
  else if (inputType === type.SingleSelect) {
    return  <SingleSelect
        options = {options}
        onSubmit = {(input) => onSubmit(input)}
      />
  }
  else if (inputType === type.MultiSelect) {
    return <MultiSelect
        options={options}
        onSubmit = {(input) => onSubmit(input)}
      />
    ;
  }
  else if (inputType === type.Boolean) {
    return <Boolean
        onSubmit={(input) => onSubmit(input)}
        page={page}
      />
    ;

  }
  else if (inputType === type.Rating) {
    return <Stars onSubmit={(input) => onSubmit(input)}/>;
  }
  else{
    return <TextInput
      onSubmit={(input) => onSubmit(input)}
    />;
  }
};




