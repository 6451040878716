export const backgroundColor = "#FFFFFF";
export const headerBackgroundColor = "#FFFFFF";
export const contentBackgroundColor = "#FAFAFA";
export const standardMargin = 20;
export const deviceTopMargin = 44;

export const cabiaiColor = {
  white: {
    only: "#FFFFFF",
  },
  brown: {
    smallTitle: "#6E5E1C",
    heading: "#453E28",
    subheading: "#734600",
    milkTea: "#DCCAA6",
  },
  yellow: {
    light: "#FFD66C",
    background: "#D1A141",
    cabi: "#FFBD3B",
  },
  grey: {
    light: "#ECECEC",
    line: "#D7D7D7",
    strong: "#C4C4C4",
    navi: "#5C5C5C",
  },
  black: {
    content: "#1D1C18",
  },
  green: {
    alien: "#D3FF56",
  },
  red: {
    warning: "#FF4444",
  },
};
