import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Choice from "./InputComponent/Choice";
import SubmitButton from "../SubmitButton";
import otherInformation from "../../otherInformation.json"
import {useCookies} from 'react-cookie';

export default ({
  onSubmit,
  page
}: {
  page?: number;
  onSubmit (e: any): void;
}) => {
  const [selected, setSelected] = useState<boolean[]>([false, false]);
  const [answer, setAnswer] = useState(0);
  const [cookies] = useCookies([
    "language"
  ]);
  useEffect(() => {
    setSelected([false, false]);
  }, [page]); 

  const onSelect = (selectedBoolean: number) => {
    if (selectedBoolean === 0){
      setSelected([true, false]);
      setAnswer(0);
    }
    else {
      setSelected([false, true]);
      setAnswer(1);
    }
  };

  const submit = () => {
    onSubmit(answer);
  }

  return (
    <BooleanWrapper>
      <Choice
        letter={"A"}
        answer={otherInformation.booleanValue[0][cookies.language]}
        onSelect={() => onSelect(1)}
        isSelected={selected[1]}
      />
      <Choice
        letter={"B"}
        answer={otherInformation.booleanValue[1][cookies.language]}
        onSelect={() => onSelect(0)}
        isSelected={selected[0]}
      />
      <SubmitButton onSubmit={submit} />
    </BooleanWrapper>
  );
};

const BooleanWrapper = styled.div`
  height: auto;
  border-radius: ${35 / 3}px;
  width: 100%;
  border-width: 1.5px;
  border-color: black;
`;
