import React from 'react';
import styled from 'styled-components'
import Button from "../components/Button";
import {standardMargin} from "../theme/color";
import otherInformation from "../otherInformation.json"
import { useCookies } from "react-cookie";

export default ({
  onSubmit,
  error
}:{
  onSubmit? ():void;
  error?: boolean;

}) => {
  const [cookies] = useCookies([
    "language"
  ]);

  return (
  <SubmitBtnWrapper>
    <Button onClick={onSubmit} isOutline={error}>
      {error? otherInformation.nextButtonError[cookies.language]: otherInformation.nextButton[cookies.language]}
    </Button>
  </SubmitBtnWrapper>);
}
const SubmitBtnWrapper = styled.div`
  margin-top: ${standardMargin*0.5}px;
`;