import React from "react";
import styled from "styled-components";
import {standardMargin} from '../../../theme/color';

export default ({
  letter,
  answer,
  onSelect,
  isSelected,
}: {
  letter?: string | number;
  answer?: string;
  onSelect?(e: any): void;
  isSelected?: boolean;
}) => {
  return (
    <ChoiceWrapper onClick={onSelect} isSelected={isSelected}>
      <OutsideLetterWrapper>
        <LetterWrapper isSelected={isSelected}>
          <LetterLabel isSelected={isSelected}>{letter}</LetterLabel>
        </LetterWrapper>
      </OutsideLetterWrapper>
      <AnswerWrapper>
        <AnswerLabel isSelected={isSelected}>{answer}</AnswerLabel>
      </AnswerWrapper>
    </ChoiceWrapper>
  );
};

interface StyledButtonProps {
  isSelected?: boolean;
}

const AnswerLabel = styled.text`
  color: ${({ isSelected }: StyledButtonProps) =>
    isSelected ? "#2E7A3B" : "black"};
`;
const OutsideLetterWrapper = styled.div`
  height: auto;
  padding: 5px;
`;

const AnswerWrapper = styled.div`
  text-align: left;
  padding: 5px;
`;

const LetterLabel = styled.text`
  color: ${({ isSelected }: StyledButtonProps) =>
    isSelected ? "white" : "black"};
`;

const LetterWrapper = styled.div`
  background-color: ${({ isSelected }: StyledButtonProps) =>
    isSelected ? "#2E7A3B" : "#C4C4C4"};
  border-radius: ${35 / 8}px;
  width: 30px;
  margin-right: 5px;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
`;

const ChoiceWrapper = styled.button`
  height: auto;
  border-radius: ${35 / 3}px;
  padding: 5px;
  background-color: white;
  width: 100%;
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ isSelected }: StyledButtonProps) =>
    isSelected ? "#2E7A3B" : "black"};
  margin-bottom: ${standardMargin/2}px;
  display: flex;
  align-items: center;
`;
