import React, {useState} from 'react'
import styled from 'styled-components'
import Disclaimer from './Disclaimer'
import LanguageSwitch from './PopUp/LanguageSwitch'

export default ({onChangeLanguage}:{onChangeLanguage (e: any):void}) =>{
  const [pressed, setPressed]= useState(false);

  const buttonOnClick = () => {
    setPressed(!pressed);
  }
  const onValue = (answer: string ) => {
    if (answer === "close"){
      setPressed(false);
    }
    else {
      onChangeLanguage(answer);
    }
    

  }

   return(
      <FooterWrapper>
        {pressed? <LanguageSwitch onAction={onValue}/>:<Disclaimer onClick={buttonOnClick}/>}
      </FooterWrapper>
   );

};


const FooterWrapper = styled.div`
  display: flex;
  max-height: 200px;
  bottom: 0%;
  position: fixed;
  width: 100%;
`;
