import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SubmitButton from '../SubmitButton';
import { useCookies } from "react-cookie";
import isEmailValid from "../../helper/isEmailVaild";
import GenderButton from './GenderButton';
import {standardMargin} from '../../theme/color';



export default ({
  page,
  type,
  onSubmit
}: {
  page?: number;
  onSubmit (e:any): void;
  type?: string;
}) => {
  const [cookies, setCookies] = useCookies(["profileBuilt", "name"]);
  const [textInput, setTextInput]= useState("");
  const [error, setError] = useState(false);
  const [gender, setGender] = useState("Ms. ");
  const [placeholder, setPlaceholder] = useState("");
  
  useEffect(() => {
    if (type === "name"){
      setPlaceholder("First Name, Last Name");
    }
    else if (type === "email"){
      setPlaceholder("hello@gmail.com");   
    }
    setTextInput("");
  }, [page, type])

  const onChange = (e: any) => {
    setTextInput(e.target.value);
    if (type === "name"){
      setCookies("name", gender+e.target.value);
    }
    setError(false);
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const onSelect = (answer: string) => {
    setGender(answer);
    setCookies ("name", answer+textInput);
  }

  const submit = () =>{

    if (type === "name"){
      if (textInput === ""){
        setError(true);
      }
      else {
        onSubmit(textInput);
      }
    }
    else if (type === "email"){
      if (isEmailValid(textInput)) {
        onSubmit(textInput);
      } else {
        setError(true);
      }
    }
    else{
      onSubmit(textInput);
    }
  }
  
  return (
    <TextInputWrapper>
      <TextInput
        value={textInput}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
      />
      {(cookies.profileBuilt == null && page === 0)  && <GenderButton onSelect={onSelect}/>}
      <SubmitButton error={error} onSubmit={submit} />
    </TextInputWrapper>
  );
};

const TextInputWrapper = styled.div`
`;

const TextInput = styled.input`
  margin-bottom: ${standardMargin}px;
  height: 60px;
  font-size: 15px;
  border-radius: ${35 / 4}px;
  box-sizing: border-box;
  width: 100%;
  display: inline-block;
  padding-left:10px;
  padding-right:10px;
  background-color: white;
  border-width: 1.5px;
  border-color: black;
`;
