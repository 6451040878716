import {profilePath} from './constants';
import {boothPath} from './constants'

export default function nextQuestionID (profileBuilt: boolean, page: number, answerID: number) {
  var nextQuestion;
  const path = profileBuilt? boothPath:profilePath;
  const optionsList = path[page].options
 
  if (
    optionsList &&
    optionsList[0] &&
    optionsList[0].hasOwnProperty("nextID")
  ) {
    var i;
    for (i=0; i<optionsList.length; i++){
      if (optionsList[i].optionID === answerID){
        nextQuestion = (optionsList[i] as any).nextID;
      }
    }

  } else {
    nextQuestion = path[page].nextID;

  }
  
  var questionIndex = 0;
  if (nextQuestion !== -1) {
    while (path[questionIndex].id !== nextQuestion) {
      questionIndex = questionIndex + 1;
    }
   } else {
     questionIndex = -1;
   }
   return questionIndex;
 };